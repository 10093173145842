/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';

const SECONDS_IN_HOUR = 3600;
export const convertSecondsToHourMinute = (seconds: number) => {
  dayjs.extend(duration);
  return dayjs.duration(seconds, 'seconds').format('HH:mm');
};
export const convertSecondsToHour = (seconds: number) => {
  dayjs.extend(duration);
  return dayjs.duration(seconds, 'seconds').format('HH');
};
export const convertSecondsToMinute = (seconds: number) => {
  dayjs.extend(duration);
  return dayjs.duration(seconds, 'seconds').format('mm');
};
export const convertMilliSecondsToDate = (milliSeconds: number) => dayjs(milliSeconds, 'seconds').format('YYYY-MM-DD');
export const convertToDateWithTime = (date: string) => dayjs(date).format('YYYY-MM-DD HH:mm:ss');
export const convertToAnyFormat = (date: string | Dayjs, format: string) => dayjs(date).format(format);
export const convertToEndOfDayWithFormat = (date: string | Dayjs, format: string) => dayjs(date).endOf('day').format(format);
export const convertTimeToSeconds = (time: string) => {
  const [hours, minutes, seconds = 0] = time.split(':').map((digits) => parseInt(digits, 10));
  dayjs.extend(duration);
  return dayjs.duration({ hours, minutes, seconds }).asSeconds();
};
export const convertStringToDate = (date: string, dateFormats: string []) => {
  dayjs.extend(customParseFormat);
  return dayjs(date, dateFormats, true);
};

export const manipulateDate = (date: number, value: number, operation: string) => {
  dayjs.extend(duration);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let dateInSeconds = dayjs.duration(date, 'seconds') as unknown as any;

  if (date === 0) {
    dateInSeconds = dayjs.duration(SECONDS_IN_HOUR * 24, 'seconds') as unknown as Dayjs;
  }

  if (operation === 'add') {
    if (dateInSeconds.add(value, 'second').asSeconds() > (SECONDS_IN_HOUR * 24)) {
      return dateInSeconds.add(value, 'second').asSeconds() - (SECONDS_IN_HOUR * 24);
    }
    return dateInSeconds.add(value, 'second').asSeconds();
  }

  if (operation === 'subtract') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const subtractedDate = dateInSeconds.subtract(value, 'second') as any;
    if (Math.sign(subtractedDate.asSeconds()) === -1) {
      return dayjs.duration(SECONDS_IN_HOUR * 24, 'seconds').subtract(Math.abs(subtractedDate.asSeconds()), 'second').asSeconds();
    }

    return subtractedDate.asSeconds();
  }
  return dateInSeconds.asSeconds();
};

export const getTimeString = (seconds: number) => {
  const time = dayjs().startOf('day').second(seconds);

  return time.format('HH:mm:ss A');
};
