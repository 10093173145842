import { useEffect, useState } from 'react';
import humps from 'lodash-humps-ts';
import { JobReviewFlow, convertToAnyFormat } from '@helpers/index';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';

type JobStatus = 'accepted' | 'rejected' | 'pending';

export type JobSituation = {
    statusStr: JobStatus;
    group: null;
    state: {
      id: number;
      comments: string;
      createdAt: string;
      createdBy: string;
      statusStr: JobStatus;
      owned: boolean;
    };
    users: {
      displayName: string;
      id: number;
    }[];
  };

type Steps = {
    jobStatus: string;
    users: () => JobSituation['users'] | null;
    comment: () => string | null;
    step: JobSituation;
    time: () => string
  }

  enum JOB_TRACE_STATUS {
    stateless = 'stateless',
    pending = 'pending',
    statusless = 'statusless'
   }

export function useGenerateJobSteps<T>(situation?: T[]) {
  const [jobSteps, setJobSteps] = useState<Steps[]>([]);
  const jobSituation = humps(situation && situation) as JobSituation[];
  const { t } = useTranslation();
  const generateJobSteps = (steps: JobSituation[]) => {
    if (steps) {
      const jobFlow = [];
      let i = 0;
      while (i < steps.length) {
        const step = steps[i];
        if (i === 0 && step.state) jobFlow.push(new JobReviewFlow('created', step));
        else if (step.statusStr === 'pending') jobFlow.push(new JobReviewFlow('pending', step));
        else if (!step.state) jobFlow.push(new JobReviewFlow('stateless', step));
        else if (step.statusStr) jobFlow.push(new JobReviewFlow(step.statusStr, step));
        else jobFlow.push(new JobReviewFlow('statusless', step));
        i += 1;
      }
      setJobSteps(jobFlow);
    }
  };

  const title = (jobStatus: string, step: JobSituation) => {
    if (jobStatus !== JOB_TRACE_STATUS.stateless) {
      const jobTraceStatus = t(`job.jobStatus.${jobStatus}`);
      const createBy = capitalize(step.state?.createdBy) || t('job.anonymous');
      const date = convertToAnyFormat(step.state?.createdAt, 'DD MMM YYYY');
      return t('job.approved', { createBy, date, jobTraceStatus });
    }
    if (jobStatus === JOB_TRACE_STATUS.stateless) return t('job.stateless');
    if (jobStatus === JOB_TRACE_STATUS.pending) return t('job.pending');
    return t('job.approvalNotRequired');
  };

  useEffect(() => {
    generateJobSteps(jobSituation);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [situation]);
  return { jobSteps, title };
}
