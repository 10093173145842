import dayjs from 'dayjs';

type JobStatus = 'accepted' | 'rejected' | 'pending';

type JobSituation = {
    statusStr: JobStatus;
    group: null;
    state: {
      id: number;
      comments: string;
      createdAt: string;
      createdBy: string;
      statusStr: JobStatus;
      owned: boolean;
    };
    users: {
      displayName: string;
      id: number;
    }[];
  };

enum JOB_TRACE_STATUS {
 stateless = 'stateless',
 pending = 'pending',
 statusless = 'statusless'
}
/* eslint-disable import/prefer-default-export */
export class JobReviewFlow {
  private status = JOB_TRACE_STATUS;

  // eslint-disable-next-line no-useless-constructor
  constructor(readonly jobStatus: string | JOB_TRACE_STATUS, readonly step: JobSituation) {}

  public time = () => dayjs(this.step.state?.createdAt).format('HH:mm:ss');

  public comment = () => {
    // eslint-disable-next-line max-len
    if ([this.status.stateless, this.status.statusless, this.status.pending].includes(this.jobStatus as JOB_TRACE_STATUS)) return null;
    if (this.step.state.comments) return this.step.state.comments;

    return 'No Comment';
  };

  public users = () => {
    if (this.jobStatus !== this.status.pending || this.step.users.length === 0) return null;
    return this.step.users;
  };
}
