import React from 'react';
import { textColor, bgColor } from '@helpers/index';
import {
  Spin, List, Space, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { map, capitalize, toLower } from 'lodash';
import { JobSituationDataProps } from './types';

const JobStatusToast = ({
  situations,
  loading,
}: {
  situations: JobSituationDataProps;
  loading: boolean;
}) => {
  const { Text } = Typography;
  const { t } = useTranslation();

  const capitalizeWord = (word: string): string => capitalize(word);

  const statusColors = {
    created: `${textColor.lightBlue} ${bgColor.lightBlue}`,
    accepted: `${textColor.green} ${bgColor.lightGreen}`,
    pending: `${textColor.orange} ${bgColor.lightOrange}`,
    rejected: `${textColor.red} ${bgColor.lightRed}`,
  };

  const displayStatus = map(situations, (situation: JobSituationDataProps, index) => (
    <React.Fragment key={index}>
      <Text className={`${statusColors[situation.statusStr]} block w-full px-3 py-2`}>
        {`${index + 1}. ${capitalizeWord(t(`job.jobStatus.${toLower(situation?.statusStr)}`))}`}
      </Text>
      <List
        dataSource={situation.users}
        renderItem={(user) => (
          <List.Item>
            <Text>{user.displayName}</Text>
          </List.Item>
        )}
        itemLayout="vertical"
        className="max-h-44 overflow-auto w-full"
      />
    </React.Fragment>
  ));

  return (
    <Space className="px-3 flex justify-center">
      <Spin spinning={loading} size="small">
        {displayStatus}
      </Spin>
    </Space>
  );
};

export default JobStatusToast;
