/* eslint-disable import/no-absolute-path */
import React from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { textColor } from '@helpers/index';
import { useGenerateJobSteps } from '@hooks/useGenerateJobSteps';
import {
  Typography, Steps, Popover, Row, Col, List, Image,
  Skeleton,
} from 'antd';
import { map } from 'lodash';
import { Job, JobSituation } from './types';
import { useTranslation } from '@lib/index';
import BlueEllipse from '/images/blue_ellipse.svg';
import GreenEllipse from '/images/green_ellipse.svg';
import RedEllipse from '/images/red_ellipse.svg';
import OrangeEllipse from '/images/orange_ellipse.svg';
import GrayEllipse from '/images/gray_ellipse.svg';

const ReviewFlow = ({
  situation,
  job,
  loading,
  changeStatus = '',
  isMainFlow,
} : {
  situation: JobSituation[],
  job: Job,
  loading: boolean,
  changeStatus?: string,
  isMainFlow: boolean
}) => {
  const { Text, Paragraph } = Typography;
  const { t } = useTranslation();
  const { Step } = Steps;
  const {
    darkBlue, green, red, orange,
    lightGrey, darkGrey,
  } = textColor;
  const approvalStatusColor = {
    created: darkBlue,
    accepted: green,
    rejected: red,
    pending: orange,
    stateless: lightGrey,
    modify: darkGrey,
  };
  const flowIcon = {
    created: BlueEllipse,
    accepted: GreenEllipse,
    rejected: RedEllipse,
    pending: OrangeEllipse,
    stateless: GrayEllipse,
    modify: GrayEllipse,
  };
  const { jobSteps, title } = useGenerateJobSteps(situation);
  const showStep = (status: string) => !(job?.statusStr === 'accepted' && status === 'pending');
  const showStepWithNoPendings = (status: string) => status !== 'pending' && !(job?.statusStr === 'accepted' && status === 'pending');
  // eslint-disable-next-line consistent-return
  const jobReviewFlow = map(jobSteps, (flow) => {
    const shouldShowStep = isMainFlow || changeStatus === 'pending'
      ? showStep(flow.jobStatus)
      : showStepWithNoPendings(flow.jobStatus);

    if (shouldShowStep) {
      return (
        <Step
          key={flow.time()}
          icon={<Image preview={false} src={flowIcon[flow.jobStatus as keyof typeof flowIcon]} />}
          title={(flow.jobStatus !== 'stateless' && flow.jobStatus !== 'pending') && flow.time()}
          subTitle={(
            <Text className={`font-semibold ${approvalStatusColor[flow.jobStatus as keyof typeof approvalStatusColor]}`}>
              {t(`job.jobStatus.${flow.jobStatus.toLowerCase()}`)}
            </Text>
          )}
          description={(
            <>
              {(flow.jobStatus !== 'stateless' && flow.jobStatus !== 'pending') ? <Paragraph>{title(flow.jobStatus, flow.step)}</Paragraph> : null }
              {flow.step.users.length > 0 && (flow.step.statusStr === 'rejected' || flow.step.statusStr === 'pending') ? (
                <Popover
                  placement="right"
                  trigger="hover"
                  content={(
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <List
                          className="w-48 h-60 overflow-auto"
                          itemLayout="horizontal"
                          dataSource={flow.step.users as unknown as JobSituation['users']}
                          renderItem={(user) => (
                            <List.Item>
                              <List.Item.Meta
                                avatar={<CaretRightOutlined />}
                                description={user.displayName}
                              />
                            </List.Item>
                          )}
                        />
                      </Col>
                    </Row>
              )}
                >
                  <Text underline>{t('job.approvalRequired')}</Text>
                </Popover>
              ) : (
                <Popover
                  placement="bottom"
                  trigger="hover"
                  content={(
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Text underline>{flow.comment()}</Text>
                      </Col>
                    </Row>
            )}
                >
                  <Text underline>{t('job.approvalComments')}</Text>
                </Popover>
              )}
            </>
          )}
        />
      );
    }
  });

  return (
    <Skeleton loading={loading}>
      <Steps labelPlacement="vertical" responsive current={2}>
        {jobReviewFlow}
      </Steps>
    </Skeleton>
  );
};

ReviewFlow.defaultProps = {
  changeStatus: '',
};

export default ReviewFlow;
